<template>
  <div class="register">
    <!-- <div v-if="passwordFormat" class="email-sent">
      <span class="email-text">
        Password must be at least 8 characters long and must contain at least one number, one special character, one
        uppercase letter, and one lowercase letter
      </span>
      <XIcon class="x-icon" @click="hidePopup" />
    </div>
    <div v-if="confEmail" class="email-sent">
      <span class="email-text"> Password reset email has been resent to {{ email }} </span>
      <XIcon class="x-icon" @click="hidePopup" />
    </div> -->
    <!-- <div class="logo-container">
      <BroncoLogo class="digital-logo" />
    </div> -->
    <h1>{{ $t('signIn.title') }}</h1>
    <div v-if="!emailSent">
      <div class="input-holder">
        <VWFormField
          class="form-field"
          :class="{ valid: email && !emailError }"
          :placeholder="$t('signIn.email')"
          type="email"
          v-model="email"
          :error="isError && !email"
          :errorMessage="emailError"
        />
      </div>
      <div class="input-holder">
        <VWFormField
          v-model="name"
          class="form-field initials"
          :class="{ valid: name && !isBadWord }"
          :placeholder="$t('signIn.yourInitials')"
          type="text"
          maxlength="2"
          :error="isBadWord || (!name && isError)"
          :errorMessage="message"
          onkeyup="name = name.toUpperCase();"
        />
      </div>
      <!-- <div class="input-holder">
        <label class="form-header"
          >PASSWORD:
          <VWFormField
            class="form-field"
            :class="{ valid: password && !mismatch }"
            placeholder="Password"
            type="password"
            v-model="password"
            :error="isError && !password"
            :errorMessage="mismatch"
        /></label>
      </div>
      <div class="input-holder">
        <label
          ><VWFormField
            class="form-field-confirm"
            :class="{ valid: confirm && !mismatch }"
            v-model="confirm"
            :error="isError && !confirm"
            :errorMessage="mismatch"
            placeholder="Confirm Password"
            type="password"
        /></label>
      </div> -->

      <div class="checkbox">
        <PassportCheckbox v-model="optIn" name="opt-in">
          <!-- @click="$event.preventDefault()" -->
          <div class="legal-copy-area" :class="{ collapsed: !legalCopyExpanded }">
            <LegalCopy :lines="$t('legal.emailConsent.lines')" />
          </div>
          <button class="expand-legal-btn" v-if="!legalCopyExpanded" @click.prevent="legalCopyExpanded = true">
            <img src="@/assets/ford/legal-expand.svg?external" />
          </button>
        </PassportCheckbox>
      </div>
    </div>

    <div v-if="!emailSent" class="button-container">
      <PassportButton :label="$t('signIn.button')" class="register-button" @click="register" />
      <!-- <PassportButton label="I already have an account" variant="text" @click="$router.push('/login')" class="log-in" /> -->
    </div>
    <!-- <div v-if="emailSent" class="confirmation">
      <span class="confirmation-text">
        We’ve sent an email to the email address you provided. Click the link in the email to confirm your registration.
      </span>
      <PassportButton label="Resend confirmation email" variant="text" @click="resendEmail" class="log-in" />
    </div> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ApiService } from '@/services/api.service';
import VWFormField from '@/components/VWFormField.vue';
// import BroncoLogo from '@/assets/ford/Horizontal_Logo.svg';
import PassportCheckbox from '@/components/PassportCheckbox.vue';
import PassportButton from '@/components/PassportButton.vue';
import LegalCopy from '@/components/LegalCopy.vue';
// import XIcon from '@/assets/icons/x_icon.svg';

// const BadWords = require('bad-words');

// const badWords = new BadWords();
export default {
  name: 'Register',
  components: {
    VWFormField,
    // BroncoLogo,
    PassportCheckbox,
    PassportButton,
    LegalCopy,
  },
  data() {
    return {
      name: '',
      // email: this.$route.params.email || '',
      password: 'DontMakeMeAdm1n!',
      confirm: 'DontMakeMeAdm1n!',
      isError: false,
      isBadWord: false,
      message: '',
      mismatch: '',
      emailError: '',
      optIn: false,
      emailSent: false,
      confEmail: false,
      passwordFormat: false,
      legalCopyExpanded: false,
    };
  },
  computed: {
    email: {
      get() {
        return this.$store.state.email;
      },
      set(val) {
        this.$store.commit('setEmail', val);
      },
    },
  },
  watch: {
    optIn() {
      // Ford requires copy to be expanded if box is checked
      if (this.optIn) this.legalCopyExpanded = true;
    },
  },
  methods: {
    ...mapActions(['parseJwt']),
    async register() {
      const profile = {
        email: this.email,
        username: this.name,
        password: this.password,
        optIn: this.optIn,
        locale: this.$store.state.locale,
        next: this.$route.query.next || null,
      };
      this.isError = true;
      // if (badWords.isProfane(this.name)) {
      //   this.isBadWord = true;
      //   this.message = 'Username cannot contain profanity ';
      // } else {
      //   this.isBadWord = false;
      //   this.message = '';
      // }
      this.isBadWord = false;
      ApiService.on(422, () => {
        this.emailError = this.$t('signIn.emailError');
      });
      // if (this.password !== this.confirm) {
      //   this.mismatch = 'Passwords do not match';
      // } else {
      //   this.mismatch = '';
      // }
      if (this.name && this.email && this.password && this.confirm && !this.isBadWord && !this.mismatch) {
        this.isError = false;
        try {
          await ApiService.post('/user', profile);
          this.signIn();
        } catch (error) {
          if (error.response.data.error === 'bad password') {
            this.passwordFormat = true;
          } else {
            this.passwordFormat = false;
          }
        }
      }
    },
    async signIn() {
      const profile = {
        email: this.email,
        password: this.password,
      };
      this.isError = true;

      if (profile.email && profile.password) {
        try {
          this.error = false;
          const { token } = (await ApiService.post('/user/auth', profile)).data;
          this.parseJwt(token);
          // this.unverified = false;
          if (this.$route.query.next) this.$router.push(this.$route.query.next);
          else this.$router.push({ name: 'tutorial', params: { hideBottomNav: true } });
        } catch (error) {
          if (error.response?.status === 422) {
            this.emailError = this.$t('signIn.emailError');
          } else {
            console.error(error);
          }
        }
      }
    },
    hidePopup() {
      this.confEmail = false;
    },
    async resendEmail() {
      const profile = {
        email: this.email,
      };
      await ApiService.post('/user/resend-verification', profile).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.emailSent = true;
          this.confEmail = true;
          setTimeout(() => {
            this.confEmail = false;
            window.location.reload();
          }, 3000);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  // align-self: center;
  // justify-content: center;
  // width: 100%;
  font-size: 1.2rem;
  // h1 {
  //   margin-top: 0;
  //   margin-bottom: 41px;
  // }
  .register-header {
    font-family: $head-font-stack;
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 5em;
    margin-bottom: 180px;
  }
  .checkbox {
    display: flex;
    align-items: flex-start;
    width: 13.5em;
    // margin-top: 1.5em;
    @include bp-sm-phone-landscape {
      width: 26em;
      margin-top: 2em;
    }
  }
  ::v-deep .g-checkbox .label.after {
    margin-left: 1em;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    color: #222;
    text-align: left;
    letter-spacing: -0.27px;
  }
  ::v-deep .g-checkbox {
    display: flex;
    align-items: flex-start;
  }
  ::v-deep .passport-checkbox .checkbox {
    margin-top: 2px;
    border-color: #222;
    @include bp-sm-phone-landscape {
      margin-top: 0;
    }
  }
  .main-logo {
    width: 3.875em;
    height: 1.875em;
    margin-top: 0.5em;
  }
  .digital-logo {
    width: 130px;
    margin-top: 16px;
  }
  .register-button {
    margin-top: 26px;
    // width: 15.625em;
    // height: 3em;
    // margin-top: 2em;
    // font-family: $head-font-stack;
    // font-size: 16px;
    // font-weight: bold;
    // text-align: center;
    // text-transform: uppercase;
  }
  ::v-deep .passport-button.text {
    font-size: 14px;
    text-decoration: underline;
  }
  .paragraph {
    margin: 0;
    margin-left: 5px;
    font-size: 18px;
    text-align: left;
  }
  .input-holder {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-header {
    margin-top: 1.5em;
    font-size: 12px;
    text-align: left;
    letter-spacing: 0.6px;
    @include bp-sm-phone-landscape {
      margin-top: 1em;
    }
  }
  .form-field {
    width: 257px;
    &.valid {
      background-image: url('../assets/icons/checkmark.svg?external');
      background-repeat: no-repeat;
      background-position: right;
      background-position-x: 95%;
      background-size: 15px;
    }
    @include bp-sm-phone-landscape {
      width: 500px;
    }
  }
  .form-field-confirm {
    width: 257px;
    margin-top: 1em;
    &.valid {
      background-image: url('../assets/icons/checkmark.svg?external');
      background-repeat: no-repeat;
      background-position: right;
      background-position-x: 95%;
      background-size: 15px;
    }
    @include bp-sm-phone-landscape {
      width: 500px;
    }
  }
  ::v-deep .vw-form-field .message {
    text-align: left;
  }
  ::v-deep .form-field.initials .input-element {
    text-transform: uppercase;
    &::placeholder {
      text-transform: none;
    }
  }
  .button-container {
    display: flex;
    flex-direction: column;
  }
  .confirmation {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-top: 1em;
    .confirmation-text {
      font-size: 18px;
      text-align: left;
      @include bp-md-tablet {
        text-align: center;
      }
    }
  }
  .log-in {
    margin-top: 1em;
  }
  .email-sent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 94px;
    background-color: $primary-color;
  }
  .email-text {
    margin-left: 1em;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }
  .x-icon {
    display: flex;
    width: 20px;
    margin-right: 1em;
  }
}
.legal-copy-area {
  // cursor: default;
  &.collapsed {
    height: 100px;
    overflow: hidden;
    mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
  }
}
.expand-legal-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  padding: 44px 120px 8px 120px;
  cursor: pointer;
  background: none;
  border: none;
  transform: translateX(-50%);
}
</style>
